<template>
  <loading-dialog :loading="$apollo.loading">
    <mf-card-container title="Configuração dag" :description="'A tela apresenta os dados da DAG do cliente'">
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="6">
              <label class="subheading">Tag:</label>
              <mf-text-input v-model="tag" disabled outlined />
            </v-col>
            <v-col cols="6">
              <label class="subheading">Agendamento:</label>
              <time-picker
                :v-model="convertCronToTime(schedule_interval)"
                :disabled="!editable"
                :time="datePickerTime"
                solo-inverted
                hide-details
                @input="formatTime($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <btn-card-actions class="pa-0" :editable.sync="editable" :saving="saving" @save="saveEdit" @cancel="cancelRestoreAccount" />
            </v-col>
            <v-col cols="auto">
              <mf-button class="mt-2" color="primary" label="Variáveis" icon="variables" @click="showVariables = true"></mf-button>
            </v-col>
            <v-col cols="auto">
              <mf-button class="mt-2" color="primary" icon="search" label="Detalhes" @click="scheduleDetails = true"></mf-button>
            </v-col>
            <v-col cols="auto">
              <mf-button class="mt-2" color="primary" icon="lock" label="Locks" @click="showLocks = true"></mf-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <base-dialog v-model="showLocks" persistent title="Locks">
        <v-row>
          <v-col>
            <locks></locks>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <mf-button color="error" icon="restart_alt" label="Sair" @click="showLocks = false"></mf-button>
          </v-col>
        </v-row>
      </base-dialog>
      <base-dialog v-model="scheduleDetails" title="Detalhes" persistent max-width="500">
        <v-container fluid>
          <v-list subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>SCHEDULE_INTERVAL (CRON)</v-list-item-title>
                <v-list-item-subtitle>{{ schedule_interval }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>START_DATE</v-list-item-title>
                <v-list-item-subtitle>{{ parameters.start_date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>LAST_START_DATE</v-list-item-title>
                <v-list-item-subtitle>{{ last_start_date === 'Data inválida' ? '-' : last_start_date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>LAST_COMPLETION_DATE</v-list-item-title>
                <v-list-item-subtitle>{{ last_completion_date === 'Data inválida' ? '-' : last_completion_date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="auto">
              <mf-button color="error" icon="restart_alt" label="Sair" @click="scheduleDetails = false"></mf-button>
            </v-col>
          </v-row>
        </v-container>
      </base-dialog>
      <dag-dialog v-model="showVariables" :variables="variables" @close="closeVariables" />
    </mf-card-container>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_UPDATE_DAG } from '@/modules/accounts/graphql'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'
import Locks from './Locks.vue'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    DagDialog: () => import('@/modules/accounts/partials/DagDialog.vue'),
    TimePicker: () => import('@/components/calendar/TimePicker.vue'),
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    BaseDialog,
    Locks
  },
  data: () => ({
    accountId: '',
    editable: false,
    showVariables: false,
    scheduleDetails: false,
    parameters: {
      start_date: ''
    },
    variables: {},
    tag: '',
    schedule_interval: '',
    running: null,
    last_start_date: '',
    last_completion_date: '',
    showLocks: false,
    saving: false
  }),
  computed: {
    datePickerTime: {
      get() {
        if (!this.schedule_interval) return ''
        return this.schedule_interval
          .split(' ')
          .filter((obj, index) => index <= 1)
          .map((obj, index) => (index === 1 ? String(Number(obj) - 3) : obj))
          .map(obj => obj.padStart(2, '0'))
          .reverse()
          .join(':')
      },
      set() {}
    }
  },
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.parameters.start_date = this.$moment(adminAccount?.dag.parameters.start_date).format('DD/MM/YYYY, HH:MM:ss')
        this.variables = adminAccount?.dag.variables
        this.tag = adminAccount?.dag.tag
        this.schedule_interval = adminAccount?.dag.schedule_interval
        this.running = adminAccount?.dag.running
        this.last_start_date = this.$moment(adminAccount?.dag.last_start_date).format('DD/MM/YYYY, HH:MM:ss')
        this.last_completion_date = this.$moment(adminAccount?.dag.last_completion_date).format('DD/MM/YYYY, HH:MM:ss')
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async saveEdit() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_DAG,
          variables: {
            id: this.accountId,
            schedule_interval: this.schedule_interval
          }
        })

        this.editable = false
        this.$snackbar({ message: 'Configurações da DAG atualizadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar configurações da DAG', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    cancelRestoreAccount() {
      this.$apollo.queries.adminAccount.refresh()
      this.editable = false
    },
    closeVariables() {
      this.showVariables = false
    },
    convertCronToTime(cron) {
      return cron
        .split(' ')
        .filter((obj, index) => index <= 1)
        .map(obj => obj.padStart(2, '0'))
        .reverse()
        .join(':')
    },
    formatTime(time) {
      const dateTime = this.$moment().utcOffset(0)
      dateTime.set({ hour: time.split(':')[0], minute: time.split(':')[1] })
      dateTime.add(3, 'hours')
      const getTime = `${dateTime.hour()}:${dateTime.minute()}`

      const minutesHoursMoment = getTime
        .split(':')
        .map((obj, index) => (index === 0 ? Number(obj) : Number(obj)))
        .reverse()
        .join(' ')

      this.schedule_interval = `${minutesHoursMoment} * * *`
      return minutesHoursMoment
    }
  }
}
</script>
