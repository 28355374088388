<template>
  <v-simple-table class="pa-2">
    <template v-slot:default>
      <thead>
        <tr>
          <th>
            Task
          </th>
          <th>
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Integrator</td>
          <td>
            <v-icon>{{ integrator ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon>
          </td>
        </tr>
        <tr>
          <td>Postgres</td>
          <td>
            <v-icon>{{ postgres ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon>
          </td>
        </tr>
        <tr>
          <td>Monet</td>
          <td>
            <v-icon>{{ monet ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon>
          </td>
        </tr>
        <tr>
          <td>Clickhouse</td>
          <td>
            <v-icon>{{ clickhouse ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon>
          </td>
        </tr>
        <tr>
          <td>MongoDB</td>
          <td>
            <v-icon>{{ mongo ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'

export default {
  data: () => ({
    accountId: '',
    integrator: null,
    postgres: null,
    monet: null,
    clickhouse: null,
    mongo: null
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.integrator = adminAccount?.locks.integrator
        this.postgres = adminAccount?.locks.postgres
        this.monet = adminAccount?.locks.monet
        this.clickhouse = adminAccount?.locks.clickhouse
        this.mongo = adminAccount?.locks.mongo
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  }
}
</script>
